<template>
  <b-card
    class="news-edit-wrapper"
  >
    <!-- form -->
    <b-form id="newsForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="标题"
            label-for="title"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="title"
              size="sm"
              v-model="news.title"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="平台"
              label-for="platform"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="platform"
                :options="getCodeOptions('news_platform')"
                v-model="news.platform"
                class="select-size-sm"
                placeholder="PC"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="是否强制看"
              label-for="is_must"
              label-size="sm"
              class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch
                inline
                id="is_must"
                v-model="news.is_must"
                value="1"
                unchecked-value="0"
                style="float: left"
                @change="showTimeLimit"
            ></b-form-checkbox>

            <b-form-input
                    id="time_limit"
                    size="sm"
                    v-model="news.time_limit"
                    type="number"
                    style="width: 50px"
                    v-show="isShow"
                />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="是否置顶"
              label-for="is_top"
              label-size="sm"
              class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch
                inline
                id="is_top"
                v-model="news.is_top"
                value="1"
                unchecked-value="0"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-card-code title="文章内容">
            <b-card-text >{{news.title}}</b-card-text>

            <quill-editor
                v-model="news.content"
                :options="snowOption"
            />

          </b-card-code>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="附件上传"
              label-for="attachments"
              label-size="sm"
              :class="'mb-1'"
          >
            <attachment-upload :theme="'files'"
                               :attachment_id="'attachments'"
                               :id="news.attachments"
                               :object_type="'news'"
                               :object_id="news.news_id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>


        </b-col>

      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store";
import newsStore from './newsStore'
import { useToast } from 'vue-toastification/composition'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import quillConfig from './quill-config.js'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'


export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    quillEditor,
    BCardCode,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      news: ref({}),
      snowOption: quillConfig,
      isShow:false,
    }
  },
  methods:{
    showTimeLimit(){
      this.isShow = !this.isShow
    },
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('news')) store.registerModule('news', newsStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('news')) store.unregisterModule('news')
    })

    const edit = function() {
      store.dispatch('news/edit', {id: this.id}).then(res => {
        this.news = res.data.data
        if(this.news.platform != null) this.news.platform = getCode('news_platform',this.news.platform)
        if(this.news.is_must == 1) this.showTimeLimit()  //显示时间限制

      })
    }

    const view = function() {
      store.dispatch('news/view', {id: this.id}).then(res => {
        this.news = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      if(this.news.title=== undefined || this.news.title === ""){
        toast.error("请填写 标题！")
        return false
      }
      if(this.news.is_must == 1){
        if(this.news.time_limit === undefined || this.news.time_limit === ""){
          toast.error("请填写 时间限制！")
          return false
        }
      }
      if(this.news.content === undefined || this.content === ""){
        toast.error("请填写 文章内容！")
        return false
      }

      store.dispatch('news/save', this.news).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-news-list'});
      })
    }

    const onUploaded = function (id, attachment, result) {
      this.news[id] = result
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onUploaded,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

</style>
